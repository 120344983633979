import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cfxpool from '@/util/cfxpool'
import VueClipboard from 'vue-clipboard2'
import VConsole from 'vconsole';
import 'normalize.css'
import scroll from 'vue-seamless-scroll'
import moment from 'moment'
import { Stepper } from 'vant';
import { CountDown } from 'vant';
Vue.use(scroll)
Vue.use(Stepper)
Vue.config.productionTip = false
Vue.prototype.$moment=moment
if(location.href.indexOf('debug=1')!==-1){
  console.log('vcon')
  new VConsole();
}
// 根据屏幕宽度是否小于等于768像素来判断是否为移动端
const isMobile = window.innerWidth <= 768;
const DeviceType = isMobile ? 'mobile' : 'pc';
// 全局对象记录当前为pc或者mobile
Vue.mixin({
  data () {
    // pc , mobile
    return {
      DeviceType
    }
  }
})
let trytimes = 0;
const init = async () => {
  if((!window.ethereum || !window.ethereum.on) && trytimes<7){
    setTimeout(()=>{
      trytimes++;
      init();
      console.log('尝试再次初始化')
    }, 500)
    console.log('未安装onekey，或者全局变量还未注入')
    return;
  }
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcuticon';
  link.href = '../assets/favicon.ico';
  document.getElementsByTagName('head')[0].appendChild(link);
  try{
    const r = await cfxpool.oneKeyInit(router)
    if (!r || r=='ProviderConflux') {
        if(r=='ProviderConflux'){
          window.location.reload()
        }
        console.log('请安装onekey')
        return
    }
    console.log("初始化结果成功:"+r)
  }catch(e){
    console.log("初始化结果失败:"+e)
  }
  store.dispatch('app/setCfxToolInit', true)
  store.dispatch('app/setEspaceInit', true)

  cfxpool.selectedAddress().then(res => {
      console.log("获取账号:" + res)
      if (res.length > 0) {
          let address = res[0]
          store.dispatch('app/setAddress', address)
          const head = address.slice(0, 6)
          const tail = address.slice(address.length - 4)
          const viewAddress = head + '....' + tail
          store.dispatch('app/setViewAddress', viewAddress)
      }
  })
  console.log("初始化钱包插件完成")
  new Vue({
      router,
      store,
      VueClipboard,
      render: h => h(App)
  }).$mount('#app')
}
Vue.prototype.$zh_en = (zh, en) => {
  if(DeviceType==='pc') return en;
  return zh;
}
Vue.prototype.$toast = (state, title, duration) => {
  const toast = Vue.extend({
    render() {
      const icon = state ?
        <svg className="icon" width="20px" height="20px" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667z m0 810.666666c-204.8 0-373.333333-168.533333-373.333333-373.333333S307.2 138.666667 512 138.666667 885.333333 307.2 885.333333 512 716.8 885.333333 512 885.333333z" />
          <path fill="currentColor"
            d="M701.866667 381.866667L448 637.866667 322.133333 512c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l149.333334 149.333333c6.4 6.4 14.933333 8.533333 23.466666 8.533334s17.066667-2.133333 23.466667-8.533334l277.333333-277.333333c12.8-12.8 12.8-32 0-44.8-14.933333-12.8-36.266667-12.8-49.066666-2.133333z" />
        </svg> :
        <svg className="icon" width="20px" height="20px" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667z m0 810.666666c-204.8 0-373.333333-168.533333-373.333333-373.333333S307.2 138.666667 512 138.666667 885.333333 307.2 885.333333 512 716.8 885.333333 512 885.333333z" />
          <path fill="currentColor"
            d="M657.066667 360.533333c-12.8-12.8-32-12.8-44.8 0l-102.4 102.4-102.4-102.4c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l102.4 102.4-102.4 102.4c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533334s17.066667-2.133333 23.466667-8.533334l102.4-102.4 102.4 102.4c6.4 6.4 14.933333 8.533333 23.466667 8.533334s17.066667-2.133333 23.466666-8.533334c12.8-12.8 12.8-32 0-44.8l-106.666666-100.266666 102.4-102.4c12.8-12.8 12.8-34.133333 0-46.933334z" />
        </svg>
      return <div>
        <div id="simple-toast">
          <div class="toast-content" style="position:fixed">
            {icon}
            <span>{title}</span>
          </div>
        </div>
      </div>
    }
  })
  let instance = new toast()
  instance.$mount()
  document.body.appendChild(instance.$el)
  setTimeout(() => {
    document.body.removeChild(instance.$el)
    instance.$destroy()
    instance = null
  }, duration || 1000)
}


init()


